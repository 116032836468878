.process-component {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.process-stages {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
.process-stage {
    flex: 1;
    position: relative;
    line-height: 2rem;
    max-height: 6rem;
    --shift-left: -1.5rem;
    --circle-size: 10px;
    --inactive-color: rgb(200, 200, 200);
    --success-color: rgb(0, 100, 0);
}
.process-stage::before {
    content: "";
    background: #333;
    border-radius: 50%;
    position: absolute;
    top: calc(1rem - var(--circle-size) / 2);
    left: calc(var(--shift-left) - var(--circle-size) / 2);
    width: var(--circle-size);
    height: var(--circle-size);
    z-index: 10;
}
.process-stage::after {
    content: "";
    background: #333;
    position: absolute;
    top: 1rem;
    left: calc(var(--shift-left) - 1px);
    height: 100%;
    width: 2px;
    z-index: 0;
}
.process-stage:last-child:after{
    height: 0;
}
.process-stage i {
    margin-left: 1rem;
}
/* inactive */
.process-stage.inactive {
    --color: rgb(163, 163, 163);
    color: var(--inactive-color)
}
.process-stage.inactive::before{
    background: var(--inactive-color)
}
.process-stage.inactive::after {
    background: var(--inactive-color)
}
/* active */
.process-stage.active {
    font-weight: bold;
    font-size: 1.25rem;
}
.process-stage.active::before{
    --circle-size: 16px;
}
.process-stage.active::after{
    background: var(--inactive-color);
}
/* success */
.process-stage.success{
    color: var(--success-color)
}
.process-stage.success::before {
    background-color: var(--success-color);
}
.process-stage.success::after {
    background-color: var(--success-color);
}
.layout{
  --header-height: 70px;
  --footer-height: 140px;
  --header-width: 1500px;
  --layout-width: 1500px;
  --navigation-width-lg: 250px;
  --navigation-width-md: 75px;
  --layout-gap: 2rem;
}

.header-container {
  width: 100%;
  background: var(--header-background);
}

.main-container {
  padding-top: var(--layout-gap);
  padding-bottom: var(--layout-gap);
  max-width: var(--layout-width);
  margin: 0 auto;
  display: flex;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.content-container {
  flex-grow: 1;
  padding-left: var(--layout-gap);
  padding-right: var(--layout-gap);
  border-left: 1px solid rgba(34,36,38,.15);
  overflow-x: visible;
  overflow-y: hidden;
}

.footer-container {
    padding-top: var(--layout-gap);
    padding-bottom: var(--layout-gap);
    min-height: var(--footer-height);
    background-color: #f9f9f9;
}
.contact-page-section {
    margin-bottom: 5rem;
}

.contact-page-section > p {
    margin-top: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.contact-page-section .label {
    display: inline-block;
    width: 10rem;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}
/*
  Added following to global styling instead encapsulating inside component because
  dynamic adding/removing .show class doesn't seem to be working with react css modules
*/

.error-summary {
  visibility: hidden;
  width: 100%;
  min-width: 500px;
  background-color: #f8d7da;
  border: 1px solid #e0b4b4;
  color: #721c24;
  padding: 16px;
  position: fixed;
  left: 0;
  top: 30px;
  font-size: 1.2rem;
  line-height: 1.9rem;
  z-index: 1;
}

@media (min-width: 768px) {
  .error-summary {
    box-shadow: 4px 4px 5px gray;
    border-radius: 4px;
    width: 700px;
    min-width: 500px;
    margin-left: -125px;
    left: 50%;
    transform: translateX(-30%);
  }
}

.error-summary.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 3.5s;
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}
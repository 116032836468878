.page {
    width: 700px;
}

.checkbox {
    font-weight: bold;
    margin-top: 1rem;
}

.warning {
    color: red;
}

.bold {
    font-weight: bold;
}
.dp-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.66rem !important;
}

.dp-form-field.inline {
    flex-direction: row;
    flex-wrap: nowrap;
}

.label-group {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
}

.label-group label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: unset !important;
    flex: unset !important;
    display: unset !important;
    align-items: unset !important;
}

.required .label-group span {
    color: #db2828;
    margin: -0.2em 0 0 0.2em;
}

.inline .label-group{
    flex: 4 0;
    overflow: hidden;
    padding-top: 0.75rem;
}
.nolabel.inline .label-group {
    visibility: hidden;
}
.nolabel:not(.inline) .label-group {
    display: none;
}

/* Input element */
.input-group {
    margin: 0;
    flex: 1;
    width: 100%;
}
.inline .input-group {
    flex: 6 0;
    min-width: 0;

    display: flex;
    flex-direction: column;
}
.input-group-control {
    width: 100%;
}
.input-group-validation {
    width: 100%;
    margin: 0;
    color: var(--error);
}

/* Ensure that every element inside control group is stretched to 100%. */
.input-group-control > * {
    width: 100% !important;
}

/* Override style frm semantic UI related to .labeled class */
.dp-input.labeled {
    display: flex !important;
}
.dp-input.labeled input {
    flex: 1 !important;
    min-width: 0;
}

.dp-input-checkbox{
    font-weight: normal;
}
/*
    Using !important is a hack to override styles inherited from App.scss.
    Without setting display to block checkbox doesn't align to the left.
    Without setting max-width to unset text inside checkbox doesn't take 100% of width.
*/
.dp-input-checkbox label{
    display: block !important;
    max-width: unset !important;
}
.dp-input-checkbox input {
    margin: 0.25rem 0.25rem 0.25rem 0;
}

input:disabled{
    background-color: #ddd !important;
}

.dp-input-textarea-read-only {
    resize: none !important;
}

.dp-input-text-read-only input,
.dp-input-text-read-only .label,
.dp-input-textarea-read-only,
.dp-input-search input:read-only {
    background-color: #f3f3f3 !important;
    cursor: not-allowed;
}

/*
    Adjust semantic UI styles of input component.
    At this moment we use this control to present searchable input field,
    for example when you select car from brand model catalog.
 */
.ui.icon.input {
    width: 100% !important;
}
.ui.icon.input input {
    margin: 0 !important;
}

input {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.dp-input-date {
    position: relative;
}

.dp-input-date .dp-input-icon {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

.dp-input-date .dp-input-icon > * {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
}
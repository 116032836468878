.file-input-fixed-postion {
  position: fixed;
  top: -100%;
}

.max-img-width {
  max-width: 200px;
}

.file-item {
  line-height: 1.1;
  font-size: 48px;
}
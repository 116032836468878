dl.descriptionList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
}

dl.descriptionList dt {
    font-weight: bold;
    width: 40%;
    margin-bottom: 0.75rem;
    flex: 0 0 auto;
}

dl.descriptionList dd {
    width: 60%;
    margin: 0;
    margin-bottom: 0.75rem;
    flex: 0 0 auto;
}
.downpayment-input {
  display: flex;
  gap: 0.5rem;
}

.downpayment-value {
  flex: 9 0;
  min-width: 0;
}

.downpayment-pct {
  flex: 7 0;
  min-width: 0;
}
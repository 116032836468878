/**
  Idea taken from bootstrap in order to manage spaces easily.
*/

$spacer: 1rem;
$s0: $spacer * 0;
$s1: $spacer * 0.25;
$s2: $spacer * 0.5;
$s3: $spacer * 1;
$s4: $spacer * 1.5;
$s5: $spacer * 3;

.m-0 {
  margin: $s0 !important;
}

.m-1 {
  margin: $s1 !important;
}

.m-2 {
  margin: $s2 !important;
}

.m-3 {
  margin: $s3 !important;
}

.m-4 {
  margin: $s4 !important;
}

.m-5 {
  margin: $s5 !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: $s0 !important;
  margin-left: $s0 !important;
}

.mx-1 {
  margin-right: $s1 !important;
  margin-left: $s1 !important;
}

.mx-2 {
  margin-right: $s2 !important;
  margin-left: $s2 !important;
}

.mx-3 {
  margin-right: $s3 !important;
  margin-left: $s3 !important;
}

.mx-4 {
  margin-right: $s4 !important;
  margin-left: $s4 !important;
}

.mx-5 {
  margin-right: $s5 !important;
  margin-left: $s5 !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: $s0 !important;
  margin-bottom: $s0 !important;
}

.my-1 {
  margin-top: $s1 !important;
  margin-bottom: $s1 !important;
}

.my-2 {
  margin-top: $s2 !important;
  margin-bottom: $s2 !important;
}

.my-3 {
  margin-top: $s3 !important;
  margin-bottom: $s3 !important;
}

.my-4 {
  margin-top: $s4 !important;
  margin-bottom: $s4 !important;
}

.my-5 {
  margin-top: $s5 !important;
  margin-bottom: $s5 !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: $s0 !important;
}

.mt-1 {
  margin-top: $s1 !important;
}

.mt-2 {
  margin-top: $s2 !important;
}

.mt-3 {
  margin-top: $s3 !important;
}

.mt-4 {
  margin-top: $s4 !important;
}

.mt-5 {
  margin-top: $s5 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: $s0 !important;
}

.me-1 {
  margin-right: $s1 !important;
}

.me-2 {
  margin-right: $s2 !important;
}

.me-3 {
  margin-right: $s3 !important;
}

.me-4 {
  margin-right: $s4 !important;
}

.me-5 {
  margin-right: $s5 !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: $s0 !important;
}

.mb-1 {
  margin-bottom: $s1 !important;
}

.mb-2 {
  margin-bottom: $s2 !important;
}

.mb-3 {
  margin-bottom: $s3 !important;
}

.mb-4 {
  margin-bottom: $s4 !important;
}

.mb-5 {
  margin-bottom: $s5 !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: $s0 !important;
}

.ms-1 {
  margin-left: $s1 !important;
}

.ms-2 {
  margin-left: $s2 !important;
}

.ms-3 {
  margin-left: $s3 !important;
}

.ms-4 {
  margin-left: $s4 !important;
}

.ms-5 {
  margin-left: $s5 !important;
}

.ms-auto {
  margin-left: auto !important;
}
.border-left {
    border-left: 1px solid rgba(34,36,38,.1) !important;
}

.tbody > tr > td:first() {
    width: auto;
    max-width: auto;
}

.tbody > tr > td {
    width: 8rem;
    max-width: 8rem;
}

.tbody > tr > td:last-child {
    width: 4rem;
    max-width: 4rem;
}
@import "./themes/default.scss";
@import "./themes/ford.scss";
@import "./themes/nissan.scss";
@import "./themes/hessel24.scss";
@import "./themes/maxus.scss";
@import "./themes/yamaha.scss";
@import "./themes/p-christensen-finans.scss";
@import "./themes/fisker.scss";
@import "./themes/mazda.scss";

/* month selection arrows in datepicker */
.react-datepicker button {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

/* buttons */
.ui.button{
  background: var(--button-primary);
  color: #fff;
}
.ui.button:hover,
.ui.button:active,
.ui.button:focus {
  background: var(--button-primary-hover);
  color: #fff;
}

.ui.button.basic {
  box-shadow: none;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
}
.ui.button.basic:hover,
.ui.button.basic:active,
.ui.button.basic:focus {
  color: var(--primary) !important;
  box-shadow: none;
  filter: none;
}

$sizeMini: 0.78571429rem !default;

.ui.table thead {
  th,
  td {
    border-top: 0px none !important;
  }
}

/* page title (like "Søg indsendte") */
h2.ui.header {
  margin-bottom: 0;
}

/* section (like "service agreemeent") title */
h3.ui.header {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.grid {
  /* also section (like "service agreemeent") title */
  h3.ui.header {
    margin-top: 0;
  }
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--link-color-hover);
}

.ui.segment.tab {
  border: 0;
  padding: 0;
}

.ui.sortable.table.basic {
  thead th {
    border-left: none;
  }

  thead th.sorted,
  thead th:hover {
    background: none;
  }
}

a {
  color: var(--link-color)
}

// Reset Semantic UI font-family of h* components
h1, h2, h3, h4, h5 {
  font-family: inherit;
}

body {
  font-family: var(--font-family);
}


/*********
START: make <button> to look like <a>, used for clearing jsx-a11y/anchor-is-valid warnings
**********/
button.link {
  text-align: left;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

button.link:hover {
  text-decoration: underline;
}

/* button.link inside table cell (.selectable), used in attachments */
td.selectable>button.link {
  padding: $sizeMini $sizeMini;
}
/*********
END: make <button> to look like <a>, used for clearing jsx-a11y/anchor-is-valid warnings
**********/



/*********
START: news display styles
**********/
.news-content td, .modal-content td  {
  border-style: solid;
  border-width: 1px;
}
/*********
END: news display styles
**********/



/*********
START: hack, disasble image adding through url in news content editor
**********/
.jodit-tabs__buttons button[data-ref="upload"]{
  display: none;
}

.jodit-tabs__buttons button[data-ref="link"]{
  display: none;
}

.jodit-drag-and-drop__file-box {
  width: 9rem;
}
/*********
END: hack, disasble image adding through url in news content editor
**********/

/* Fix bug in semantic UI definition. They set min-width to empty string which is invalid CSS value. */
.ui.search.dropdown {
  min-width: unset !important;
}

/* Override fixed min-width set by semantic UI. */
.ui.selection.dropdown {
  min-width: unset !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
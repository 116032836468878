.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .6);
    width: 100%;
    height: 100vh;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.message{
    padding: 2rem;
    z-index: 2001;
    background-color: white;
    border: 1px solid black;
    border-radius: 0.25rem;
    display: flex;
}

.message .icon {
    margin-right: 2rem;
}

.message .seconds {
    text-align: center;
    font-size: 2rem;
    margin-top: 0.75rem;
}

/* Hide icon on small devices */
@media screen and (max-width: 768px){
    .message .icon{
        display: none;
    }
}
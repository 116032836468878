.header {
  height: var(--header-height);
  color: var(--header-color);
  display: flex;
  align-items: stretch;
  max-width: var(--layout-width);
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navigation-container {
  width: var(--navigation-width-md);
  padding-left: 25px;
  padding-right: 25px;
  flex-shrink: 0;
  flex-grow: 0;
}

.navigation-container.open {
  width: var(--navigation-width-lg);
}

.content-container {
  padding-left: 2rem;
  padding-right: 2rem;
  border-left: 1px solid var(--header-color);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Enforce that all nested component use same color of the text. */
.content-container a,
.content-container i {
  color: inherit !important;
}

.logo-container {
  display: none;
  height: 100%;
  padding: var(--logo-padding);
  flex-grow: 0;
  flex-shrink: 0;
}

.logo-container > * {
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.menu-button-container {
  display: flex;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
}

.menu-button-container > * {
  width: 25px;
  height: 25px;
  display: block;
}

@media (min-width: 1281px) {
  .navigation-container {
    width: var(--navigation-width-lg);
  }

  .logo-container {
    display: block;
  }

  .menu-button-container {
    display: none;
  }
}
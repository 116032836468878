#welcome-page-brand {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
}

#login-button-container {
  margin-top: 3rem;
}

#login-button {
  display: block;
}

#secondary-buttons-container {
  margin-top: 3rem;
}

#secondary-buttons-container a {
  color: #999;
  display: block;
}

#secondary-buttons-container a:hover {
  color: #333;
  text-decoration: none;
}

p.welcome-p {
  margin: 0;
  margin-bottom: 1rem;
}

td.cell {
    white-space: nowrap;
}

td a {
    color: inherit !important;
}

tr i.cancel:hover,
tr i.delete:hover {
    color: rgb(220,53,69);
}

td.no-data-cell {
    background: #fff;
}

td.no-data-cell .no-data-text {
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    margin: 40px 20px;
    padding: 40px 20px;
    text-align: center;
    font-size: 1.2em;
    font-style: italic;
}
.dp-table {
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-collapse: initial;
    border-radius: .28571429rem;
    border-spacing: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.87);
    margin: 1em 0;
    text-align: left;
    width: 100%;
  
    thead {
      -webkit-box-shadow: none;
      box-shadow: none;
  
      tr {
        &:first-child > th:first-child {
          border-radius: .28571429rem 0 0 0;
        }
  
        > th:first-child {
          border-left: none;
        }
      }
  
      th {
        background: #f9fafb;
        border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        border-left: 1px solid rgba(34, 36, 38, 0.15);
        color: rgba(0, 0, 0, 0.87);
        cursor: auto;
        font-style: none;
        font-weight: 700;
        padding: .92857143em .78571429em;
        text-align: inherit;
        text-transform: none;
        vertical-align: inherit;
        white-space: nowrap;
      }
    }
  
    ::selection {
      background-color: #cce2ff;
      color: rgba(0, 0, 0, 0.87);
    }
  
    &.selectable tbody tr:hover, tbody tr td.selectable:hover {
      background: rgba(0, 0, 0, 0.05) !important;
      color: rgba(0, 0, 0, 0.95) !important;
    }
  
    tr:first-child td {
      border-top: none;
    }
  
    [class*="middle aligned"], &[class*="middle aligned"] {
      vertical-align: middle;
    }
  
    tr td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
    }
  
    td {
      padding: 0.78571429em;
      text-align: inherit;
      -webkit-transition: background .1s ease,color .1s ease;
      transition: background .1s ease,color .1s ease;
    }
  
    th {
      -webkit-transition: background .1s ease,color .1s ease;
      transition: background .1s ease,color .1s ease;
    }
  
    tfoot {
      -webkit-box-shadow: none;
      box-shadow: none;
  
      th {
        background: #f9fafb;
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        color: rgba(0, 0, 0, 0.87);
        cursor: auto;
        font-style: normal;
        font-weight: 400;
        padding: 0.78571429em;
        text-align: inherit;
        text-transform: none;
        vertical-align: middle;
      }
    }
  
    td.cell {
      white-space: nowrap;
    }
  
    &.compact tbody tr td {
      padding: 0.4em 0.6em;
    }

    &.celled tbody tr td {
      border-left: 1px solid rgba(34, 36, 38, 0.1);

      &:nth-child(1) {
        border-left: none;
      }
    }
  }
  
#card-container {
  min-height: 100vh;
  background-color: #EEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  padding: 2rem;
}

#card {
  background-color: #fff;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 450px;
  --border: 1px solid #CCC;
  box-shadow: 1px 0px 6px 2px #CCC;
}

#card-header {
  background-color: var(--header-background);
  width: 100%;
  height: 75px;
  padding: 1rem 3rem;
}

#card-header-logo {
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 150px;
}

#card-content {
  padding: 3rem;
  width: 100%;
}
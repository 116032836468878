.header {
    padding: 4px 8px
}

.icon {
    color: var(--primary);
}

.body{
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 5rem;
    background: #e3e3e3;
  }
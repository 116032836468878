.message {
    overflow: hidden;
    word-break: break-all;
}

.message > a {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

.document-types-container {
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.document-types-container > *:not(:last-child) {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid rgba(34,36,38,.15);
}

.document-type-container > *:not(:last-child){
    margin-bottom: 1rem;
}

.no-documents-container {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.buttons-container {
 display: flex;
 flex-direction: row;
}

.buttons-container > div:first-child {
    flex: 1;
}
.buttons-container > div:last-child {
    text-align: right;
}
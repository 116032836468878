body {
  --primary: #da291c;
  --header-background: var(--primary); 
  --header-color: #fff;
  --error: #9f3a38;
  --logo-url: url('./default-logo.png');
  --link-color: var(--primary);
  --link-color-hover: #1e70bf;
  --logo-padding: 0;
  // --font-family: Arial, Helvetica, sans-serif; - we should use Arial font as fallback for Santander font, but it looks bad.
  // The Lato,... font family is default one for Semantic UI.
  --font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  --button-primary: var(--primary);
  --button-primary-hover: #b92318;
}
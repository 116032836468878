.button-bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 0.5rem;
}

.close-button {
    order: 0
}

.download-button {
    order: 1
}
.busy-indicator-wrapper {
    position: relative;
}

.busy-indicator-wrapper:not(.busy) > .busy-indicator {
    display: none;
}

.busy-indicator-wrapper.busy > .busy-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

/* Do not present nested busy indicators. */
.busy-indicator-wrapper.busy .busy-indicator-wrapper > .busy-indicator {
    display: none;
}
.footer{
    margin-top: 1.5rem;
    color: #777;
    font-size: smaller;
}

.hr { 
    background-color: rgba(34,36,38,.15);
    height: 1px;
    border: 0;
    margin: 1.5rem 0;
}

.finish-previous-step{
    color: #777
}

.section-number {
    display: inline-block;
    width: 1.5rem;
}
.section-content {
    margin-left: 1.5rem;
}
.item {
  margin-bottom: 3rem !important;
}

.header {
  font-size: 1.25rem;
  font-weight: 600;
}

.content {
  margin-top: 0.5rem;
}

.footer {
  color: #bbb;
  margin-top: 0.5rem;
}

.footer i {
  margin-right: 1rem;
}

.footer a {
  color: inherit;
}

.no-news-message {
  font-size: 1.3em;
  line-height: 1.8em;
}
.page {
    max-width: 50rem;
}

.button {
    display: inline-block;
    background-color: rgb(237, 28, 36);
    color: #fff;
    padding: 2rem 4rem;
    font-weight: bold;
    margin: 1rem 0;
}
.button:hover {
    text-decoration: none;
    color: #fff;
}

.center {
    text-align: center;
}

.separator {
    margin-bottom: 3rem;
}
.navigation {
    position: sticky;
    top: 1rem;
    width: var(--navigation-width-md);
    overflow: hidden;
    padding-left: 25px;
    padding-right: 25px;
}

.navigation.open {
    width: var(--navigation-width-lg);
}

.navigation-group {
    color: #666;
    font-weight: 300;
    margin-top: 2.5rem;
}

.navigation-group:first-child{
    margin-top: 0;
}

.navigation .navigation-header {
    visibility: hidden;
}

.navigation.open .navigation-header{
    visibility: visible;
}

.navigation-item a {
    background-color: initial;
    display: block;
    color: #777;
    font-weight: 400;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
}

.navigation-item a:hover {
    text-decoration: none;
    color: #111;
}

.navigation-item a.active {
    font-weight: bold !important;
    color: #000;
}

.navigation .navigation-item .icon {
    width: 25px;
    height: 25px;
    display: block;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    flex-grow: 0;
}

.navigation .navigation-item .label {
    display: none;
}

.navigation.open .navigation-item .label {
    display: block;
}

.navigation .navigation-item .counter {
    display: none;
}

.navigation.open .navigation-item .counter {
    display: block;
}

@media (min-width: 1281px) {
    .navigation {
        width: var(--navigation-width-lg);
    }

    .navigation .navigation-header {
        visibility: visible;
    }

    .navigation .navigation-item .icon {
        width: 25px;
        height: 25px;
        margin: unset;
    }

    .navigation .navigation-item .label {
        display: block;
    }
    
    .navigation .navigation-item .counter {
        display: block;
    }
}
.container {
    width: 30rem;
    position: fixed;
    right: 1rem;
    top: 1rem;
    z-index: 1000;
}

.message {
    padding: 0.75rem;
    margin-bottom: 1rem;
}

.error {
    background-color: #fff6f6;
    border: 1px solid #e0b4b4;
    color: #9f3a38;
}

.success {
    background-color: #fcfff5;
    border: 1px solid #a3c293;
    color: #2c662d;
}

.container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    color: #333;
    max-width: var(--layout-width);
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
}

.container > div {
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .container {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

.container a {
    color: inherit;
}

.container h5 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}
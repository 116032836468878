.buttons {
    width: 100%;
    max-width: 700px;
    display: flex;
}

.left {
    flex-grow: 1;
}

.right {
    flex-grow: 0;
}
.section{
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
}

.button{
    color: var(--primary);
    background-color: transparent;
    border: none;
    cursor: pointer;
}
